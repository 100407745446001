import { StyledFullModalWrapper } from '@/Components/atoms/FullModal/style';
import { css, styled } from '@mui/material/styles';

const HomePageWrapper = styled('section')<{ ['data-ismobile']: boolean }>`
  /* padding-bottom: 5rem; */

  ${StyledFullModalWrapper} {
    .close-btn {
      top: 0;
      right: 0;

      svg {
        path {
          stroke: ${props => props.theme.customTheme.colors.dark};
        }
      }
    }
  }

  .home_products {
    &__title {
      margin-top: 0;
      margin-bottom: 1.5rem;
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5;
    }

    &__subtitle {
      margin-top: 0;
      margin-bottom: 1.5rem;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      color: #818181;
    }

    &__container {
      display: flex;
      gap: 30px;
      align-items: flex-start;
      position: relative;
    }

    &__sidebar {
      width: 200px;
      border-radius: 12px 12px 0 0;
      flex-shrink: 0;

      position: sticky;
      top: 30px;

      & > * {
        &:not(:last-child) {
          margin-bottom: 3rem;
        }
      }
    }

    &__main {
      width: 100%;
    }

    &__filter-state-wrapper {
      padding-bottom: 30px;
    }

    &__filter-state {
      margin: 0;
      margin-bottom: 0.5rem;
      padding: 0;
      list-style: none;

      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 20px;

      li {
        min-width: calc((100% - 150px) / 6);
        padding: 9px 12px;
        background-color: ${props => props.theme.customTheme.colors.primary};
        color: #fff;
        border-radius: 12px;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
        flex-shrink: 0;
      }
    }

    &__list {
    }
  }

  a[data-tooltip-id] {
    padding: 10px;
    margin-bottom: 0 !important;
    border-radius: 0.5rem;
    background-color: ${props => props.theme.customTheme.bg.primary};
    color: #fff;
    margin-right: 10px;
    cursor: pointer;
    width: 180px;
    height: auto;
    display: inline-block;
  }
  #my-tooltip-category,
  #my-tooltip-filter {
    z-index: 88;
    background-color: ${props => props.theme.customTheme.bg.primary} !important;
    opacity: 1;
    padding: 0;
    border-radius: 0.75rem;
  }

  .full-modal-body {
    & > div {
      box-shadow: none;
      border-radius: 0;
    }
  }

  ${props =>
    props['data-ismobile']
      ? css`
          padding-top: 1rem;
          padding-bottom: 3rem;

          .home_products {
            &__sidebar {
              display: flex;
              width: 100%;
            }
            &__main {
              width: 100%;
            }
            &__container {
              flex-direction: column;
            }

            &__filter-state-wrapper {
              padding-right: 0;
              margin-bottom: 15px;
            }

            &__filter-state {
              flex-wrap: wrap;
              gap: 10px;
              li {
                width: auto;
              }
            }

            &__list {
              .rec{
                font-size: 14px !important;
              }
            }
          }
        `
      : ''}
`;

export { HomePageWrapper };
