import React, { useState } from 'react';
import Container from '@/Components/atoms/Container';
import { useIsMobile } from '@/Hooks/useIsMobile';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import List from '../ListPagination';
import { HomePageWrapper } from './style';
import Categorys from '../Categorys';
import { IProductItem } from '@/Models/Product/IProduct';
import Banners from '@/Page/Homepage/Components/Banners/Banners';
import GridTypeButtons from '@/Components/moleculas/ProductList/GridTypeButtons';
import BaseButton from '@/Components/atoms/BaseComponents/BaseButton';
import PlusSvg from '@/Components/atoms/CommonSvg/plusSvg';
import { useRouter } from 'next/router';

type Props = {
  data: IProductItem[];
  page: number;
  loading: boolean;
  totalPages: number;
  totalElements: number;
  handlePage: (page: number) => Promise<void>;
  getParams: object;
};

const MainProductList = (props: Props) => {
  // @ts-ignore
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [isVisibleFilter, setIsVisibleFilter] = useState(true);
  
  // const { data, page, totalPages, totalElements} = useContextSelector(SearchFilterContext, v => v.state);
  const { data, page, totalPages, totalElements,loading, handlePage, getParams } = props;

  return (
    <HomePageWrapper data-ismobile={isMobile} className='home_products' id={'homepage-product-list'}>
      <Box pb={2} pt={2}>
        <Banners />
      </Box>
      {!isMobile && (
        <Box mb={2}>
          <Categorys />
        </Box>
      )}
      <Container>
        <div className='home_products__main'>
          <div className='home_products__list'>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <p style={{ color: '#00040F', fontSize: 20 }} className='rec'>{t('recommendation')}</p>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <GridTypeButtons />
              </div>
            </Box>

            {data.length < 1 && !loading ? (
              <Box mb={4}>
                <Typography variant='h5' component={'p'}>
                  {t('search_page.no_items2')}
                </Typography>
                
              </Box>
            ) : (
              <>
                <List
                  totalElements={totalElements}
                  nextPageParams={getParams}
                  data={data}
                  page={page}
                  handlePage={handlePage}
                  loading={loading}
                  totalPages={totalPages}
                />
              </>
            )}
          </div>
          {/* {!isMobile && <FAQ />} */}
        </div>
      </Container>
    </HomePageWrapper>
  );
};

export default MainProductList;
