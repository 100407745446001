import { styled } from "@mui/material/styles";

const StyledFullModalWrapper = styled('div')<{ visible: boolean }>`
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  box-sizing: border-box;

  background-color: #fff;
  /* padding: 15px; */
  /* visibility: ${props => props.visible ? 'visible' : 'hidden'}; */
  display: ${props => props.visible ? 'block' : 'none'};

  .body {
    position: relative;
    z-index: 2;
  }

  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 3;

    /* border: 2px solid ${props => props.theme.customTheme.colors.primary}; */
    color: ${props => props.theme.customTheme.colors.darkestPrimary};
    outline: none;
    background-color: transparent;
    border: none;
    width: 40px;
    height: 43px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg{
      path{
        stroke:${props => props.theme.customTheme.colors.primary};
      }
    }
  }
`;

export {
  StyledFullModalWrapper,
};
