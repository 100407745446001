import apiService from '../ApiService';

export type BannerItem = {
  id: number;
  title: string;
  price: number;
  description: string;
  link: string;
  isActive: boolean;
  photo: {
    createdAt: string;
    updatedAt: string;
    id: string;
    path: string;
    url: string;
    width: null | number;
    height: null | number;
    contentType: string;
    type: string;
  };
};

async function getBanners() {
  return await apiService.setUrl(`/banners/get-all-by-type?bannerType=TOP_MAIN_BANNER`).setMethod('GET').request<{ data: any[] }>();
}

async function increaseBannerView(id: number) {
  return apiService.setUrl(`/banners/${id}/increase-view`).setMethod('GET').request()
}

const BannerApi = {
  getBanners,
  increaseBannerView
};

export default BannerApi;
