import { GetServerSideProps } from 'next';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'next-i18next';
import { IInitialServerProducts } from '@/Page/SearchPage/Context/ServerState/actions/getInitialProducts';
import { WEBSITE_URL } from '@/Data/Constants';
import numberService from '@/Services/NumberService';
import Homepage from '@/Page/Homepage';
import { myGetServerSidePropsFuncType, sharedServerSideLogic } from '@/Services/SsrService/sharedServerSideLogic';
import { productModel } from '@/Models/Product/productModel';
import ProductApi from '@/Services/Axios/Controllers/ProductApi';
import { getPremiumCountByDevice } from '@/Page/SearchPage/Helpers/getPremiumCount';
import { QUERY_KEYS } from '@/ServerState';
import BannerApi from '@/Services/Axios/Controllers/BannerApi';

// TODO: canonicalda page param bo'lishi shart

export default function Search({ initialOrderNum }: { initialProducts: IInitialServerProducts; initialOrderNum: number }) {
  const { t } = useTranslation(['common']);

  return (
    <>
      <NextSeo
        title={t('common:seo_info.index.title')!}
        description={t('common:seo_info.index.description')!}
        canonical={WEBSITE_URL}
        openGraph={{
          url: WEBSITE_URL,
          title: t('common:seo_info.index.title')!,
          description: t('common:seo_info.index.description')!,
          images: [
            {
              url: 'https://beta.joyla.uz/static/icons/logo.svg',
              alt: 'Joyla',
            },
          ],
          siteName: 'Joyla',
        }}
      />
      <>
        <Homepage initialOrderNum={initialOrderNum} />
      </>
    </>
  );
}

const fn: myGetServerSidePropsFuncType = async (context, commonValues) => {
  const initialOrderNum = numberService.getRandomNumber(11, 100);
  const params = productModel.getQueryParamsFromUrl(context.resolvedUrl);

  // const fallbackResponse = await ProductApi.getProducts(
  //   {
  //     ...productModel.prepareSearchParams({
  //       ...params,
  //       size: commonValues.savedUserPreferences.pageSize,
  //       user_lat: commonValues.savedUserPreferences.location.user_lat,
  //       user_lang: commonValues.savedUserPreferences.location.user_lang,
  //       orderNum: initialOrderNum,
  //       premiumCount: getPremiumCountByDevice(commonValues.isMobile ? 'mobile' : 'computer'),
  //     }),
  //   },
  //   commonValues.client_token
  // );

  let banners_data: any[] = [];

  // try {
  //   const response = await BannerApi.getBanners();
  //   banners_data = response?.data?.data ?? [];
  // } catch (error) {
  //   logger.log('get banners ssr request error: ', error);
  // }

  return {
    props: {
      initialOrderNum,
      swrFallbackData: {
        [QUERY_KEYS.GET_HOMEPAGE_BANNERS]: banners_data,
      },
    },
  };
};

export const getServerSideProps: GetServerSideProps = sharedServerSideLogic(fn);
