import React, { FC, useEffect, useState } from 'react';
import ProductList from '@/Components/moleculas/ProductList';
import { IProductItem } from '@/Models/Product/IProduct';
import { RequestStatus } from '@/Services/Axios';
import { PaginationRounded } from '@/Components/atoms/Pagination';
import { useIsMobile } from '@/Hooks/useIsMobile';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '@/Components/atoms/Loader';
import { colors } from '@/styles/colors';
import ProductApi from '@/Services/Axios/Controllers/ProductApi';
import { TODO_ANY } from '@/Types/CommonTypes';
interface IListProps {
  data: IProductItem[];
  page: number;
  totalPages: number;
  handlePage: (page: number) => void;
  loading: boolean;
  nextPageParams: TODO_ANY;
  totalElements: number;
}

const List: FC<IListProps> = ({ data, handlePage, loading, page, totalPages, nextPageParams, totalElements }) => {
  const isMobile = useIsMobile();
  const [localPage, setLocalPage] = useState(1);
  const [localProcucts, setLocalProducts] = useState<IProductItem[]>(data);

  const onChangePage = (_: any, _page: number) => {
    handlePage(_page);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 150);
  };

  const handleInfinityData = async () => {
    const newLocalPage = localPage + 1;
    setLocalPage(newLocalPage);
    const { data } = await ProductApi.getProducts({
      ...nextPageParams,
      page: newLocalPage - 1,
    });
    setLocalProducts([...localProcucts, ...data.content]);
  };

  useEffect(() => {
    if (isMobile) {
      setLocalPage(page);
      setLocalProducts(data);
    }
  }, [data, page]);


  return (
    <>
      {isMobile && (
        <>
          <InfiniteScroll dataLength={localProcucts.length} next={handleInfinityData} hasMore={localProcucts.length < totalElements} loader={<Loader color={colors.primary} size={50} type='block' />}>
            <ProductList loading={loading} data={localProcucts || []} />
          </InfiniteScroll>
        </>
      )}

      {!isMobile && (
        <>
          <ProductList data={data} loading={loading} />
          <br />
          <PaginationRounded alignItems='center' count={totalPages} disabled={loading} page={page} onChange={onChangePage} color='primary' variant='text' style={{ marginBottom: 100 }} />
        </>
      )}
    </>
  );
};

export default List;
