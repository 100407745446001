import Container from '@/Components/atoms/Container';
import MyLink from '@/Components/atoms/MyLink';
import { pageLinks } from '@/Data/pageLinks';
import { useIsMobile } from '@/Hooks/useIsMobile';
import BannerApi from '@/Services/Axios/Controllers/BannerApi';
import { getImageUrlByPath } from '@/Services/Image';
import { logger } from '@/Services/Logger';
import { Box, styled } from '@mui/material';
import { ElementRef, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const StyledBox = styled(Box)`
    .banner-container {
        /* border-radius: 12px; */
        padding: 0;
        position: relative !important;
        width: 100%;
    }

    img {
        width: 100%;
    }

    .banner-decorative-icon {
        position: absolute;
        top: 0;
        left: 0;
    }

    .banner-item {
        padding: 0 0px 35px 0px;
        overflow: hidden;
        /* height: 480px; */

        img {
            /* height: 480px; */
            width: 100%;
            min-width: 100%;
            object-fit: cover;
            border-radius: 12px;
            display: block;
            margin: 0 auto;
        }
    }

    .swiper-wrapper {
        height: auto;
    }

    .swiper-pagination-bullets {
        width: 400px !important;
        position: absolute !important;
        bottom: 0px;
        left: calc(50% - 200px) !important;
    }

    .swiper-pagination-bullet {
        width: 60px;
        height: 5px;
        border-radius: 5px;
    }

    .swiper-pagination-bullet-active {
        position: relative;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100px;
            height: 100px;
            background-color: green;
        }
    }

    @keyframes dot {
        0% {
            width: 0;
        }
        100% {
            width: 60px;
        }
    }

    @media (max-width: 991.98px) {
        transform: translateY(-32px) !important;
        margin-bottom: -32px !important;
        .banner-container {
            padding: 0 !important;
        }
        .banner-item {
            padding: 0;
            /* max-height: 340px; */
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                /* height: 340px; */
                width: auto;
                max-width: 100%;
                border-radius: 0;
                /* object-fit: cover; */
            }
        }
        .swiper-pagination-bullet {
            width: 30px;
            background-color: #fff;
        }
    }

    @media (max-width: 560px) {
        .banner-item {
            /* max-height: 220px; */
            img {
                /* height: 220px; */
            }
        }
    }
    @media (max-width: 450px) {
        .banner-item {
            /* max-height: 180px; */
            img {
                /* height: 180px; */
                width: auto;
                max-width: 100%;
                object-fit: fill;
            }
        }
    }
    @media (max-width: 320px) {
        .banner-item {
            /* max-height: 160px; */
            img {
                /* height: 160px; */
                width: auto;
                max-width: 100%;
                object-fit: fill;
            }
        }
    }
`;

type IBannerItem = {
    id: number;
    title: string;
    description: string;
    linkResponseDTO?: {
        customUrl?: string;
        categoryId?: number;
        productId?: number;
        createdBy?: number;
        sellerId?: number;
        linkType?: 'SELLER' | 'PRODUCT' | 'CATEGORY' | 'CUSTOM';
    };
    calculatedSize?: {
        width: number;
        height: number;
        url: string;
    };
    actionLink?: string;
    photos?: {
        WEB?: {
            id: string;
            url: string;
            name: string;
            path: string;
            width: number;
            height: number;
        };
        TABLET?: {
            id: string;
            url: string;
            name: string;
            path: string;
            width: number;
            height: number;
        };
        MOBILE?: {
            id: string;
            url: string;
            name: string;
            path: string;
            width: number;
            height: number;
        };
    };
};

const platformNames = {
    WEB: 'WEB',
    TABLET: 'TABLET',
    MOBILE: 'MOBILE',
} as const;

type PlatformType = (typeof platformNames)[keyof typeof platformNames];

function normalizeBanners(_banners: any[], _wrapperWidth: number, _platform: PlatformType) {
    const banners = _banners ?? [];
    const wrapperWidth = _wrapperWidth ?? 1300;
    const platform = _platform ?? 'WEB';

    return banners
        ?.map(item => {
            try {
                const image = item?.photos?.[platform];

                const width = image.width < wrapperWidth ? wrapperWidth : image.width;
                const aspect = image.width / image.height;
                const height = width / aspect;

                const finalWidth = Math.floor(width);
                const finalHeight = Math.floor(height);

                const actionLink = getActionLink(item.linkResponseDTO);

                if (!actionLink) {
                    throw new Error('Error when getting url from linkResponseDTO');
                }

                return {
                    ...item,
                    calculatedSize: {
                        width: finalWidth,
                        height: finalHeight,
                        url: getImageUrlByPath(image?.path, finalWidth, finalHeight),
                    },
                    actionLink,
                };
            } catch (error) {
                logger.log('Error when normalizing banner item: ', error, item);
                return null;
            }
        })
        .filter(i => !!i);
}

function getActionLink(linkData: any) {
    switch (true) {
        case Boolean(linkData?.linkType === 'CATEGORY' && linkData?.categoryId): {
            return pageLinks.categoryPage(linkData?.categoryId);
        }
        case Boolean(linkData?.linkType === 'SELLER' && (linkData?.sellerId || linkData?.createdBy)): {
            return pageLinks.sellerDetail(linkData?.sellerId || linkData?.createdBy);
        }
        case Boolean(linkData?.linkType === 'PRODUCT' && linkData?.productId): {
            return pageLinks.productDetailPage(linkData?.productId);
        }
        case Boolean(linkData?.linkType === 'CUSTOM' && linkData?.customUrl): {
            return linkData?.customUrl;
        }
        default: {
            return null;
        }
    }
}

const Banners = () => {
    const isMobile = useIsMobile();

    const wrapperRef = useRef<ElementRef<'div'>>();
    const [loading, setLoading] = useState(true);

    const [banners, setBanners] = useState<IBannerItem[]>([]);

    useEffect(() => {
        const platform = getCorrectPlatform(window.innerWidth);
        BannerApi.getBanners()
            .then(response => {
                const normalizedBanners = normalizeBanners(response?.data?.data ?? [], wrapperRef?.current?.clientWidth!, platform);
                setBanners(normalizedBanners);
            })
            .catch(error => {
                logger.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const getCorrectPlatform = (width: number) => {
        if (width > 992) {
            return platformNames.WEB;
        } else if (width <= 992 && width >= 768) {
            return platformNames.TABLET;
        } else {
            return platformNames.MOBILE;
        }
    };

    const handleClickBanner = (id: number) => {
        BannerApi.increaseBannerView(id);
    };

    if (loading) {
        return (
            <Container>
                <Skeleton count={1} height={isMobile ? 220 : 350} width={'100%'} />
            </Container>
        );
    }

    if (!banners.length) {
        // NOTE: if banners don't exist then not render banners at all
        return null;
    }

    return (
        <StyledBox>
            <Container>
                <Box className='banner-container' ref={wrapperRef}>
                    <Box>
                        <Swiper
                            loop={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            pagination={{ clickable: true }}
                            modules={[Pagination, Autoplay]}
                        >
                            {banners.map((item: IBannerItem) => {
                                const size = item.calculatedSize!;

                                return (
                                    <SwiperSlide key={item?.id} style={{ padding: 0 }}>
                                        <MyLink href={item?.actionLink!} onClick={() => handleClickBanner(item.id)}>
                                            <Box className='banner-item'>
                                                <img src={size.url} alt='' />
                                            </Box>
                                        </MyLink>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </Box>
                </Box>
            </Container>
        </StyledBox>
    );
};

export default Banners;
