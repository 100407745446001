import React from 'react';
import CategoryWrap from './style';
import Container from '@/Components/atoms/Container';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { pageLinks } from '@/Data/pageLinks';
import { getNameKeyByLang, getSlugNameByLang } from '@/Utils/getNameKeyByLang';
import { useGetCategories } from '@/ServerState/hooks/useGetCategories';
import { getImageUrlByPath } from '@/Services/Image';
import CustomImage from '@/Components/atoms/Image';

type Props = {};

const Categorys = (props: Props) => {
  // @ts-ignore
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as 'uz' | 'ru';
  const getCategoriesQuery = useGetCategories();

  const data = getCategoriesQuery?.data?.data || [];

  return (
    <CategoryWrap>
      <Container>
        <div className='wrap'>
          {data.map((cat, index) => (
            <Link className='box' href={pageLinks.categoryPage(cat[getSlugNameByLang(currentLanguage)])} key={cat.id}>
              <div>
                {cat[getNameKeyByLang(currentLanguage)]}
                {/* <img src={`/static/category-icon/cat${index+1}.png`}  alt={cat[getNameKeyByLang(currentLanguage)]} /> */}
                <img src={getImageUrlByPath(cat.photoPath, 96, 72)}  alt={cat[getNameKeyByLang(currentLanguage)]} />
              </div>
            </Link>
          ))}
        </div>
      </Container>
    </CategoryWrap>
  );
};

export default Categorys;
