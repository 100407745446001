import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import PublicLayout from '@/Components/layouts/ResponsivePublicLayout';
import MainProductList from './Components/Main';
import { useRouter } from 'next/router';
import ProductApi from '@/Services/Axios/Controllers/ProductApi';
import { _DEFAULT_GEO } from '@/Services/Coordinates';
import useIsFirstRender from '@/Hooks/useIsFirstRender';
import MobileDrawer from '@/Components/atoms/MobileDrawer';
import CategoryList from '@/Components/moleculas/CategoryFilter';
import { IMinCategory } from '@/Models/Category';
import { pageLinks } from '@/Data/pageLinks';
import { getSlugNameByLang } from '@/Utils/getNameKeyByLang';
import { useMyTranslation } from '@/Hooks/useMyTranslation';
import HomeMobileHeader from '../SearchPage/Components/MobileComponents/Header';
import { useIsMobile } from '@/Hooks/useIsMobile';
import { VoidFunction } from '@/Data/Constants';
import { useUserPreferencesState } from '@/Hooks/useUserPreferencesState';
import { productModel } from '@/Models/Product/productModel';
import { getPremiumCountByDevice } from '@/Page/SearchPage/Helpers/getPremiumCount';
import { regionValueType } from '@/Components/moleculas/AddressDropdown/Dropdown';
import useRequest from '@/Hooks/useRequest';
import { SORT_KEYS } from '@/Models/Product/IProduct';
import { SearchFilterProvider } from '@/Page/SearchPage/Context/FilterContext';

interface IHomepageProps {
  children?: React.ReactNode;
  title?: string;
  initialOrderNum: number;
}

const Homepage: FC<IHomepageProps> = ({ initialOrderNum: orderNum }) => {
  const { language, t } = useMyTranslation();
  const router = useRouter();
  const isMobile = useIsMobile();

  const [visibleCategory, setVisibleCategory] = useState(false);
  const isFirstRender = useIsFirstRender();
  const [page, setPage] = useState(+productModel.getQueryParamsFromUrl(router.asPath).page);
  const pageSize = useUserPreferencesState('pageSize');
  const location = useUserPreferencesState('location');

  const nextPageParams = useMemo(() => {
    return {
      page,
      size: pageSize,
      orderNum,
      premiumCount: getPremiumCountByDevice(isMobile ? 'mobile' : 'computer'),
      user_lat: location.user_lat,
      user_lang: location.user_lang,
      sort: SORT_KEYS.NEWEST,
    };
  }, [page]);

  const getProductsQuery = useRequest(
    async abortParams => {
      const query_params_for_push = productModel.prepareQueryParamsToUrl(nextPageParams);
      router.push(router.pathname + query_params_for_push, undefined, { shallow: true });

      const response = await ProductApi.getProducts(
        {
          ...productModel.prepareSearchParams(nextPageParams),
        },
        undefined,
        abortParams?.signal
      );
      return response.data;
    },
    {
      enabled: true,
      dependencies: [],
      notifyErrorEnabled: true,
    }
  );

  const [productsList, totalElements, totalPages] = useMemo(() => {
    if (getProductsQuery.data) {
      return [getProductsQuery.data.content, getProductsQuery.data.totalElements, getProductsQuery.data.totalPages];
    } else {
      return [[], 0, 0];
    }
  }, [getProductsQuery.data]);

  const refetch = () => {
    // NOTE: page set bo'lib olishini kutamiz
    setTimeout(() => {
      getProductsQuery.refetch();
    }, 100);
  };

  const onSearch = useCallback((keyword: string) => {
    router.push(`/search?name=${keyword}`);
  }, []);

  const handlePage = async (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (isFirstRender) return;
    refetch();
  }, [page]);

  const onCloseCategory = useCallback(() => {
    setVisibleCategory(false);
  }, []);

  const openCategory = useCallback(() => {
    setVisibleCategory(true);
  }, []);

  const onSelectCategory = useCallback((category: IMinCategory) => {
    router.push(pageLinks.categoryPage(category[getSlugNameByLang(language)]));
  }, []);

  return (
    <PublicLayout visibleMobileNavbar onSearch={onSearch}>
      {isMobile && (
        <HomeMobileHeader
          hasFilterBtn={false}
          onSubmit={onSearch}
          openCategory={openCategory}
          openFilter={VoidFunction}
          onSelectCategory={onSelectCategory}
          resetFilter={VoidFunction}
        />
      )}
     

      <MainProductList
        data={productsList}
        handlePage={handlePage}
        getParams={nextPageParams}
        page={page}
        loading={getProductsQuery.loading}
        totalElements={totalElements}
        totalPages={totalPages}
        />
      <MobileDrawer keepMounted bordered open={visibleCategory} onClose={onCloseCategory} title={t('categories')!}>
        <CategoryList onSelect={onSelectCategory} closeOnAction={onCloseCategory} withoutTitle />
      </MobileDrawer>
    </PublicLayout>
  );
};

export default Homepage;
