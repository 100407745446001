import { styled } from '@mui/material/styles';

const CategoryWrap = styled('div')`
  /* ul {
    display: flex;
    gap: 20px;
    padding: 7px 0;
    margin: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      height: 3px;
      border-radius: 5px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color:transparent;
    }

    &:hover{
      ::-webkit-scrollbar{
        display: block;
        background-color: #aaa;
      }
      ::-webkit-scrollbar-thumb {
      background-color: rgba(9,143,205, 0.8);
    }
    }

    li {
      list-style: none;

      a {
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 20px;
        border: 1px solid rgba(12, 136, 198, 0.8);
        color: #0c88c6;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: transparent;
        transition: all 0.3s ease;

        &:hover {
          background: #0c88c6;
          color: #fff;
          font-weight: 500;
        }
      }

    }
  } */

  .wrap {
    display: flex;
    /* gap: 1rem; */
    justify-content: space-between;
    flex-wrap: wrap;

    .box {
      height: 100px;
      width: 19%;
      border-radius: 16px;
      background-color: #fff;
      box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.08);
      padding: 16px 0 0 16px;
      margin-bottom: 16px;
      position: relative;
      text-decoration: none;
      overflow: hidden;

      div{
        color: #00040F;
        font-weight: 500;
        display: flex;

        img{
          position: absolute;
          right: 0;
          top: 30px;
          object-fit: cover;
          height: 70px;
        }
      }

      &:hover{
        box-shadow: 0px 6px 12px 0 rgba(0, 0, 0, 0.12);
        transition: 300ms;
      }
    }
  }
`;

export default CategoryWrap;
